import Home from './Home';
import Projects from './Projects'
import Navbar from './components/Navbar'
import About from './About'

function App() {
  return (
    <>
      <Navbar/>
      <Projects/>
    </>
  );
}

export default App;
